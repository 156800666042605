import { PropertyDetailsFragment } from '__generated__/athena';

export { usePropertyReactions } from './usePropertyReactions';
export { useSimilarProperties } from './useSimilarProperties';
export * as useViewCount from './useViewCount';
export { useTrackProperty } from './useTrackProperty';
export { convertToSavedSearch, formatExistingSavedSearch } from './savedSearchConverters';

export const is3PExclusive = (property: PropertyDetailsFragment) =>
  property.isExclusive && property.originatorType === 'THIRD_PARTY_SELLER';

import { createContext, useCallback, useContext } from 'react';

import { useAuth, User } from '@opendoor/auth-fe';
import { EventTrackingAction } from '@opendoor/observability/slim';

import { PropertyDetailsFragment } from '__generated__/athena';

import { useObservability } from '../../../helpers/observability';
import { DEPLOY_ENV } from '../../globals';
import { getAnonymousId } from '../../shared/trackers';

interface EventData {
  [key: string]: unknown;
}

interface TrackProperty {
  addressToken?: string;
  city?: string;
  state?: string;
  zip?: string;
  listingState?: string;
  canSelfTour?: boolean;
  slug?: string;
}

// Action names should follow this format
type PropertyEventAction =
  | 'property-view'
  | 'property-favorite'
  | 'property-share'
  | 'property-images'
  | 'property-self-tour'
  | 'property-buy-direct';

interface PropertyAnalyticsProps {
  property?: PropertyDetailsFragment;
}

const initialPropertyContext: PropertyAnalyticsProps = {
  property: undefined,
};

export const PropertyAnalytics = createContext(initialPropertyContext);

export const usePropertyAnalytics = () => useContext(PropertyAnalytics);

/**
 * Geenerate the payload for the trackProperty function, exported for testing
 */
export function buildPayload<T extends EventData>(
  property?: PropertyDetailsFragment,
  additionalContextualData: T = {} as T,
  user?: User | null,
) {
  const trackPropertyFields: TrackProperty = {
    ...(property?.city && { city: property?.city }),
    ...(property?.state && { state: property?.state }),
    ...(property?.zip && { zip: property?.zip }),
    ...(property?.slug && { slug: property?.slug }),
    ...(property?.addressToken && { addressToken: property?.addressToken }),
    ...(property?.listingState && { listingState: property?.listingState }),
    ...(property?.canSelfTour && { canSelfTour: property?.canSelfTour }),
  };

  const properties = {
    ...additionalContextualData,
    ...trackPropertyFields,
    anonymousId: getAnonymousId(),
    ...(user?.customerUuid && { customerId: user.customerUuid }),
  };
  // Filter out any keys where the value is empty
  const payload = Object.keys(properties)
    .filter((key) => properties[key])
    .reduce((obj: { [key: string]: unknown }, key) => {
      obj[key] = properties[key];
      return obj;
    }, {});

  return payload;
}

export const useTrackProperty = () => {
  const analytics = useObservability();
  const { property } = usePropertyAnalytics();
  const { user } = useAuth();
  const trackProperty = useCallback(
    function <T extends EventData>(
      featureName: PropertyEventAction,
      label?: string,
      additionalContextualData: T = {} as T,
    ) {
      const action = featureName as EventTrackingAction;
      const payload = buildPayload(property, additionalContextualData, user);
      analytics.trackEvent(action, label, payload);

      if (DEPLOY_ENV === 'development') {
        console.debug(`[Analytics]`, { featureName, label, payload });
      }
    },
    [analytics.trackEvent, property],
  );

  return { trackProperty };
};

export default useTrackProperty;

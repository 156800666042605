import { useEffect } from 'react';

export const PROPERTY_DETAIL_PAGE_VIEWS = '@cosmos/marketplace/propertyDetailPageViews';
export const VIEW_COUNT_THRESHOLD = 5;

export function useViewCount() {
  useEffect(() => {
    if (!localStorage) {
      return;
    }
    const viewCount = localStorage.getItem(PROPERTY_DETAIL_PAGE_VIEWS);
    if (viewCount) {
      localStorage.setItem(PROPERTY_DETAIL_PAGE_VIEWS, (Number(viewCount) + 1).toString());
    } else {
      localStorage.setItem(PROPERTY_DETAIL_PAGE_VIEWS, '1');
    }
  }, []);

  if (typeof window === 'undefined') {
    return { viewCount: VIEW_COUNT_THRESHOLD };
  }
  return { viewCount: Number(localStorage.getItem(PROPERTY_DETAIL_PAGE_VIEWS)) };
}

import { useCallback, useEffect, useReducer } from 'react';

import { useAuth } from '@opendoor/auth-fe';
import { useRouter } from 'next/router';

import { OdProtosBuyerV2Data_ReactionSource } from '__generated__/athena';
import { MarketplacePropertyCategory_ReactionEventReaction } from '__generated__/protobuf/journey/data/categories/marketplace_property_category';

import { getAthenaClient } from 'components/api';
import useTrackProperty from 'components/marketplace/helpers/useTrackProperty';

import { useObservability } from '../../../helpers/observability';

const redirectToLogin = (addressId: string) => {
  const url = new URL(window.location.toString());
  url.searchParams.append('likedId', addressId);
  window.location.assign(`/login?redirectTo=${encodeURIComponent(url.toString())}`);
  return;
};

type ReactionState = {
  isDisliked: boolean;
  isLiked: boolean;
  isLoading: boolean;
};
const initialState: ReactionState = {
  isDisliked: false,
  isLiked: false,
  isLoading: false,
};

type ReactionActions =
  | {
      type: 'START_TOGGLE_FAVORITE';
    }
  | {
      type: 'SET_FAVORITE';
      payload: { isLiked: boolean };
    };

const checkIfLiked = async (addressToken?: string | null) => {
  try {
    if (addressToken) {
      const res = await getAthenaClient().GetMarketplacePropertyReaction({ addressToken });
      return res.marketplace.property?.isLiked ?? false;
    }
    return false;
  } catch {
    return false;
  }
};

const likeProperty = async (
  addressId: string,
  customerId: string,
  source: OdProtosBuyerV2Data_ReactionSource,
) => {
  try {
    await getAthenaClient().SetMarketplacePropertyReaction({
      input: { addressId, customerId, reaction: 'REACTION_LIKE', source },
    });
    return true;
  } catch (e) {
    return false;
  }
};

const unlikeProperty = async (addressId: string, customerId: string) => {
  try {
    await getAthenaClient().RemoveMarketplacePropertyReaction({ input: { addressId, customerId } });
    return false;
  } catch (e) {
    return true;
  }
};

export const usePropertyReactions = (
  addressId?: string | null,
  source?: OdProtosBuyerV2Data_ReactionSource | null,
) => {
  const { authentication, user } = useAuth();
  const router = useRouter();
  const isLoggedIn = authentication.state === 'authenticated';
  const { trackProperty } = useTrackProperty();
  const { trackJourneyEvent } = useObservability();
  const likedIdExists = router.query['likedId'] === addressId;

  const onFinishLikedId = () => {
    delete router.query['likedId'];
    router.replace(
      {
        query: router.query,
      },
      undefined,
      { shallow: true },
    );
  };

  useEffect(() => {
    if (!isLoggedIn || !addressId) {
      return;
    }
    if (likedIdExists && user?.customerUuid) {
      dispatch({ type: 'START_TOGGLE_FAVORITE' });
      likeProperty(addressId, user?.customerUuid, source ?? 'REACTION_SOURCE_UNKNOWN')
        .then((res) => {
          dispatch({ type: 'SET_FAVORITE', payload: { isLiked: res } });
        })
        .finally(() => {
          onFinishLikedId();
        });
      return;
    }
    checkIfLiked(addressId).then((res) => {
      dispatch({ type: 'SET_FAVORITE', payload: { isLiked: res } });
    });
  }, [isLoggedIn, likedIdExists]);

  const reducer: (state: ReactionState, action: ReactionActions) => ReactionState = (
    state,
    action,
  ) => {
    switch (action.type) {
      case 'START_TOGGLE_FAVORITE':
        if (state.isLiked) {
          return { isLiked: false, isLoading: true, isDisliked: false };
        }
        return { isLiked: true, isLoading: true, isDisliked: false };
      case 'SET_FAVORITE':
        return { isLiked: action.payload.isLiked, isLoading: false, isDisliked: false };
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const onClickReaction = useCallback(async () => {
    if (!addressId) {
      return;
    }
    if (!isLoggedIn && addressId) {
      trackProperty('property-favorite', 'redirect-to-login');
      return redirectToLogin(addressId);
    }
    dispatch({ type: 'START_TOGGLE_FAVORITE' });
    const trackingLabel = state.isLiked ? 'remove-favorite' : 'add-favorite';
    const propertyReaction = state.isLiked ? unlikeProperty : likeProperty;
    const reactionLabel = state.isLiked
      ? MarketplacePropertyCategory_ReactionEventReaction.REACTION_REMOVE_LIKE
      : MarketplacePropertyCategory_ReactionEventReaction.REACTION_ADD_LIKE;
    if (user?.customerUuid) {
      dispatch({
        type: 'SET_FAVORITE',
        payload: {
          isLiked: await propertyReaction(
            addressId,
            user?.customerUuid,
            source ?? 'REACTION_SOURCE_UNKNOWN',
          ),
        },
      });
      trackProperty('property-favorite', trackingLabel);
      trackJourneyEvent('marketplaceProperty', 'reaction', {
        addressUuid: addressId,
        reaction: reactionLabel,
        source: source ? source : 'marketplace-pdp',
      });
      const onClickReactionEvent = new Event('propertyReactionEvent');
      window.dispatchEvent(onClickReactionEvent);
    }
  }, [state.isLiked, isLoggedIn, user]);

  return { onClickReaction, isLiked: state.isLiked, isLoading: state.isLoading };
};

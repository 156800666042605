import { useEffect, useState } from 'react';

import { SimilarPropertiesFragment } from '__generated__/athena';

import { getAthenaClient } from 'components/api';

export const useSimilarProperties = (addressToken?: string) => {
  const [similarProperties, setSimilarProperties] = useState<
    SimilarPropertiesFragment | null | undefined
  >();

  useEffect(() => {
    if (!addressToken) {
      return;
    }
    getAthenaClient()
      .GetMarketplacePropertySimilarProperties({ addressToken })
      .then((data) => {
        setSimilarProperties(data?.marketplace?.property?.similarProperties);
      });
  }, [addressToken]);

  return { similarProperties };
};

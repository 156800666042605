import {
  OdProtosBuyerV2Data_PropertyType,
  OdProtosBuyerV2Data_SavedSearchInput,
  OdProtosBuyerV2Data_SearchOrigin,
} from '__generated__/athena';

import { AgentSavedSearch, SearchPreferences } from 'components/marketplace/agent-email/types';

const MAX_LOT_SIZE = 500000000;
const MAX_PRICE = 500000000;
const MIN_YEAR_BUILT = 1900;
const MAX_YEAR_BUILT = 2025;
const PRICE_MULTIPLIER = 100;

export const convertToSavedSearch = (
  searchPreferences: SearchPreferences,
  zoneIds: string[],
): OdProtosBuyerV2Data_SavedSearchInput => {
  return {
    bedrooms: {
      min: searchPreferences?.minBeds ? parseFloat(searchPreferences.minBeds) : 0,
    },
    bathrooms: {
      min: searchPreferences?.minBaths ? parseFloat(searchPreferences.minBaths) : 0,
    },
    fhaEligibleOnly: Boolean(searchPreferences?.fhaEligible) || false,
    hasPoolOnly: Boolean(searchPreferences?.mustHavePool) || false,
    lotSqft: {
      min: searchPreferences?.minLotSize ? parseFloat(searchPreferences.minLotSize) : 0,
      max: searchPreferences?.maxLotSize ? parseFloat(searchPreferences?.maxLotSize) : MAX_LOT_SIZE,
    },
    ...(searchPreferences?.noHoas && {
      maxMonthlyHoaFee: {
        value: 0,
        currencyCode: 'USD',
      },
    }),
    name: searchPreferences?.name,
    origin: 'SEARCH_ORIGIN_BUYERS_AGENT' as OdProtosBuyerV2Data_SearchOrigin,
    price: {
      min: {
        value: searchPreferences?.minPrice ? parseFloat(searchPreferences.minPrice) : 0,
        currencyCode: 'USD',
      },
      max: {
        value: searchPreferences?.maxPrice ? parseFloat(searchPreferences.maxPrice) : MAX_PRICE,
        currencyCode: 'USD',
      },
    },
    propertyTypes: [
      ...(searchPreferences?.singleFamily
        ? ['PROPERTY_TYPE_HOME' as OdProtosBuyerV2Data_PropertyType]
        : []),
      ...(searchPreferences?.townhome
        ? ['PROPERTY_TYPE_TOWNHOME' as OdProtosBuyerV2Data_PropertyType]
        : []),
      ...(searchPreferences?.condo
        ? ['PROPERTY_TYPE_CONDO' as OdProtosBuyerV2Data_PropertyType]
        : []),
    ],
    yearBuilt: {
      min: searchPreferences?.minYearBuilt
        ? parseFloat(searchPreferences.minYearBuilt)
        : MIN_YEAR_BUILT,
      max: searchPreferences?.maxYearBuilt
        ? parseFloat(searchPreferences.maxYearBuilt)
        : MAX_YEAR_BUILT,
    },
    zoneIds,
  };
};

export const formatExistingSavedSearch = (savedSearch: AgentSavedSearch): SearchPreferences => {
  return {
    name: savedSearch?.name,
    minPrice: savedSearch?.minPrice ? (savedSearch?.minPrice * PRICE_MULTIPLIER).toString() : '',
    maxPrice: savedSearch?.maxPrice ? (savedSearch?.maxPrice * PRICE_MULTIPLIER).toString() : '',
    minBeds: savedSearch?.minBeds ? savedSearch?.minBeds.toString() : '',
    minBaths: savedSearch?.minBaths ? savedSearch?.minBaths.toString() : '',
    singleFamily: savedSearch?.singleFamily,
    condo: savedSearch?.condo,
    townhome: savedSearch?.townhome,
    fhaEligible: savedSearch?.fhaEligible,
    mustHavePool: savedSearch?.mustHavePool,
    minYearBuilt: savedSearch?.minYearBuilt ? savedSearch?.minYearBuilt.toString() : '',
    maxYearBuilt: savedSearch?.maxYearBuilt ? savedSearch?.maxYearBuilt.toString() : '',
    minLotSize: savedSearch?.minLotSize ? savedSearch?.minLotSize.toString() : '',
    maxLotSize: savedSearch?.maxLotSize ? savedSearch?.maxLotSize.toString() : '',
    noHoas: savedSearch?.noHoas,
  };
};
